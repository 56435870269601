import { Box, makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(theme => ({
  tagContainer: {
    background: theme.palette.primary.main,
    borderRadius: "2px",
    padding: "6px 8px",
  },
  tagText: {
    fontFamily: "Geogrotesque",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
}))

export const DepartmentTag = ({ departmentName }) => {
  const classes = useStyles()

  return (
    <Box display="flex">
      <Box className={classes.tagContainer}>
        <Typography className={classes.tagText}>{departmentName}</Typography>
      </Box>
    </Box>
  )
}
