import {
  Box,
  Container,
  Typography,
  makeStyles,
  useTheme,
  Button,
  ListItem,
} from "@material-ui/core"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout-home"
import { DepartmentTag } from "../components/open-positions/department-tag"
import Openpositions1 from "../images/positions/openpositions1.png"
import Openpositions2 from "../images/positions/openpositions2.png"
import Dote from "../components/services/dote"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "16px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textContainer: {
    width: "70%",
    marginRight: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
    },
  },
  line: {
    fontSize: "16px",
    color: "#F4F5F6",
    paddingLeft: "0px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
  },
}))

export default function OpenPositionDetailPage({ data }) {
  const { openPosition } = data.strapi
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Layout>
      <Box pt="100px">
        <Container>
          <Box my={5}>
            <Typography variant="h1">{openPosition.name}</Typography>
            <DepartmentTag departmentName={openPosition.department.name} />
          </Box>
        </Container>
        <Box
          mb={5}
          borderBottom={`2px solid ${theme.palette.primary.main}`}
        ></Box>
        <Container>
          <Box className={classes.container}>
            <Box className={classes.textContainer}>
              <Typography variant="h3">Description</Typography>
              <Box my={4}>
                <Typography variant="caption">REQUIREMENTS</Typography>
                <Typography variant="body1" style={{ marginBottom: "16px" }}>
                  Donec id elit non mi porta gravida at eget metus. Donec id
                  elit non mi porta gravida at eget metus. Etiam porta sem
                  malesuada magna mollis euismod. Cras justo odio, dapibus ac
                  facilisis in, egestas eget quam. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  sed odio dui.
                </Typography>
                <ListItem className={classes.line}>
                  <Dote /> Lorem ipsum dolor
                </ListItem>
                <ListItem className={classes.line}>
                  <Dote /> Lorem ipsum dolor
                </ListItem>{" "}
                <ListItem className={classes.line}>
                  <Dote /> Lorem ipsum dolor
                </ListItem>
              </Box>
              <Box my={4}>
                <Typography variant="caption">REQUIREMENTS</Typography>
                <Typography variant="body1">
                  Donec id elit non mi porta gravida at eget metus. Donec id
                  elit non mi porta gravida at eget metus. Etiam porta sem
                  malesuada magna mollis euismod. Cras justo odio, dapibus ac
                  facilisis in, egestas eget quam. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  sed odio dui.
                </Typography>
              </Box>
              <Box my={4}>
                <Typography variant="caption">REQUIREMENTS</Typography>
                <Typography variant="body1">
                  Donec id elit non mi porta gravida at eget metus. Donec id
                  elit non mi porta gravida at eget metus. Etiam porta sem
                  malesuada magna mollis euismod. Cras justo odio, dapibus ac
                  facilisis in, egestas eget quam. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  sed odio dui.
                </Typography>
              </Box>
              <Typography variant="h3">what we offer</Typography>
              <Box my={4}>
                <Typography variant="caption">REQUIREMENTS</Typography>
                <Typography variant="body1">
                  Donec id elit non mi porta gravida at eget metus. Donec id
                  elit non mi porta gravida at eget metus. Etiam porta sem
                  malesuada magna mollis euismod. Cras justo odio, dapibus ac
                  facilisis in, egestas eget quam. Cum sociis natoque penatibus
                  et magnis dis parturient montes, nascetur ridiculus mus. Donec
                  sed odio dui.
                </Typography>
              </Box>
              <Button variant="contained">apply now!</Button>
            </Box>
            <Box width="100%" maxWidth="500px" my={8}>
              <img src={Openpositions1} alt="openposition1" width="100%"></img>
              <img src={Openpositions2} alt="openposition2" width="100%"></img>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query OpenPositionsDetailQuery($id: ID!) {
    strapi {
      openPosition(id: $id) {
        id
        name
        department {
          name
        }
        apply_link_url
        description {
          ... on STRAPI_ComponentOpenPositionContentLead {
            text
          }
          ... on STRAPI_ComponentOpenPositionContentSection {
            header
            text
          }
          ... on STRAPI_ComponentOpenPositionContentVideo {
            url
          }
          ... on STRAPI_ComponentOpenPositionContentImage {
            image {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
